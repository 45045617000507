body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

p {
  margin-bottom: 0;
}

/******************header***************/
.header {
  background: #ffffff;
  border-bottom: 0.3px solid #00000024;
  padding: 0;
}

.fa.fa-bars.navbar-toggler-icon {
  color: black;
}

.header .form-control {
  background: rgba(116, 123, 132, 0.08);
  border-radius: 7px;
  border: none;
  font-style: normal;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  line-height: 21px;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0 !important;
  margin-left: 0px;
  border-bottom-left-radius: 0 !important;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.header .form-control::placeholder {
  color: #572f5b;
}

.header .nav-link {
  cursor: pointer;
}

.header .input-group-text {
  background: rgba(116, 123, 132, 0.08);
  border-radius: 7px;
  height: 40px;
  border: none;
}

/*********************left side****************/
.left_side {
  background: #ffffff;
  padding-top: 4px;
  min-height: calc(100vh - 73px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.left_side .nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
}

.left_side .purple {
  display: none;
}

.left_side img {
  margin-right: 10px;
}

.left_side .active .white {
  display: none;
}

.left_side .active .purple {
  display: inline;
}

.left_side .navbar-nav {
  margin-top: 25px;
}

.left_side .foot {
  margin-top: 0;
}

/***************right side*********************/
.left_col h3 {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.right_side {
  padding: 18px 25px;
  min-height: 92vh;
}

.right_side.accounts_page {
  padding: 9px 25px;
}

.right_side .nav-tabs {
  border-bottom: none;
  background: #ffffff;
  margin-bottom: 25px;
  border-radius: 10px;
  padding: 8px 18px 0px;
}

.right_side .nav-tabs .nav-link.active {
  background-color: #fff;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-bottom: 2px solid #572f5b;
  border-radius: 20px 20px 0px 0px;
  line-height: 21px;
  color: #333333;
}

.header .form-control:focus {
  color: #212529;
  background-color: rgba(116, 123, 132, 0.08);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}

.input-group-text {
  cursor: pointer;
}

.right_side .form-control:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: unset;
}

.right_side .columns {
  background: #ffffff;
  box-shadow: 0px 2.65437px 59.7232px rgba(163, 171, 185, 0.24);
  border-radius: 5px;
  padding: 22px 16px 10px;
  margin-bottom: 25px;
}

.right_side .columns img {
  margin-right: 5px;
}

.right_side .columns h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8fa0b9;
}

.right_side .columns h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #464646;
}

.right_side .nav-tabs .nav-link {
  margin-bottom: 0;
  background: 0 0;
  border: none;
  margin-right: 32px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.right_side .nav-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #c2c2c2;
}

.right_side .total_number {
  width: 70%;
}

.right_side .arrow {
  width: 30%;
}

.right_side .head_name {
  margin-bottom: 20px;
}

.right_side.accounts_page .head_name {
  margin-bottom: 10px;
}

.right_side .table_sec .table {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
}

.right_side .table> :not(:first-child) {
  border-top: none;
}

.right_side .table th {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  color: #333333;
}

.right_side .table td {
  color: #6c757d;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
}

.right_side .table thead {
  background: #f2f2f2;
  border-radius: 10px;
}

.right_side .pagination .page-link {
  background: #ffffff;
  border: 1px solid #bbbbbb;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4a4a4a;
}

.right_side .table .success {
  color: #66b718;
}

.right_side .table .fail {
  color: #fa5f1c;
}

.right_side .actions a {
  background: #af5e9d;
  border-radius: 5px;
  font-style: normal;
  text-decoration: none;
  padding: 10px 13px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.right_side .head_top a {
  background: #ffffff;
  border: 1px solid #f5ebf5;
  border-radius: 5px;
  display: block;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  text-decoration: none;
  color: #181818;
}

.right_side .head_top.search_field {
  width: 28%;
}

.right_side .actions a img {
  margin-right: 15px;
}

.right_side .head_top a img {
  margin-left: 8px;
}

.right_side .actions,
.right_side .head_top {
  margin-right: 16px;
}

.right_side .action_last_col {
  margin-right: 0;
}

.right_side .head_top .form-control {
  background: #ffffff;
  border-right: none !important;
  border: 1px solid #f5ebf5;
  border-radius: 5px;
}

.right_side .head_top .input-group-text {
  background: #ffffff;
  border: 1px solid #f5ebf5;
  border-left: none !important;
  border-radius: 5px;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.contacts_sec {
  background: #ffffff;
  padding-top: 10px;
  text-transform: capitalize;
  /* height: 670px;
  overflow-y: scroll; */
}

.contacts_sec h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right_side .add_tag,
.save_btn {
  background: linear-gradient(109.47deg,
      #572f5b 13.06%,
      #af5e9d 67.2%,
      #572f5b 101.91%) !important;
  border: 1px solid rgba(87, 47, 91, 0.47);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.right_side .head_top.upload a img {
  margin-left: 0;
}

.head_top .dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none;
}

.right_side .head_top .dropdown-item {
  background: transparent;
  border: none;
  padding: 7px 13px;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #181818;
}

.head_top .dropdown-menu {
  background: #fafafa;
  box-shadow: 0px 0px 6px rgba(24, 24, 24, 0.12);
  border-radius: 5px;
  border: none;
}

.save_btn {
  display: inline !important;
  padding: 10px 30px !important;
}

.contact_name h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6c757d;
}

.contacts_list {
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  margin-top: 14px;
  padding: 5px 0px;
}

.tag_cols h2 {
  color: #181818;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  margin-right: 10px;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tags_sec .tag_icons {
  display: none;
}

.tag_cols:hover .tag_icons {
  display: flex;
  position: absolute;
  bottom: 0;
}

.tags_sec .tag_cols {
  padding: 13px 0px 0px;
  border-radius: 5px;
  min-height: 100px;
  padding-bottom: 13px;
  position: relative;
}



.tag_icons .action_icons.icon {
  border-radius: 0px 0px 0px 5px;
}

.tags_sec .tag_col_sec {
  padding-right: 0;
  margin-bottom: 20px;
}

.tag_icons .action_icons {
  border-radius: 0px 0px 0px 0px;
  padding: 1px 5.4px !important;
  margin: 0px 5px 0px 5px;
  cursor: pointer;
}

.tag_icons .action_icons.paint_icon {
  border-radius: 0px 0px 5px 0px;
  margin-right: 0;
}

.right_side p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #767476;
}

.account_profile .profile_cols {
  background: #ffffff;
  padding: 18px 20px;
  border-radius: 10px;
}

.account_profile .profile_cols .form-control {
  background: #ffffff;
  border: 1.44444px solid #f2eeee;
  border-left: none;
  border-radius: 10px;
  padding-left: 0;
}

.account_profile .profile_cols .input-group-text {
  background: #ffffff;
  border-right: none !important;
  border: 1.44444px solid #f2eeee;
  border-radius: 10px;
}

.profile_cols h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.team_Profile_cols h4 {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 9px;
  color: #333333;
}

.team_Profile_cols {
  background: #fcf8fd;
  border-radius: 5px;
  padding: 20px 0px;
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.team_Profile_cols_sec {
  margin-top: 15px;
}

.tag_cols .form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #181818;
}

.tag_cols .form-control:focus {
  background: #eaf4e0;
  border: none;
}

.account_page .tag_cols {
  padding: 9px;
  border-radius: 5px;
}

.right_side .account_page {
  padding-left: 29px;
  padding-right: 21px;
}

.bulk_message_cols p {
  font-family: "Arial";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #181818;
  margin-bottom: 7px;
}

.left_col h3 img {
  margin-right: 12px;
}

.right_side .message_sec {
  margin-bottom: 15px;
}

.bulk_message_cols span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-right: 27px;
  color: #747b84;
  margin-left: 10px;
}

.target_friend .btn {
  background: #572f5b;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  padding: 7px 21px;
  color: #ffffff;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: unset;
}

.target_friend .dropdown-menu {
  border: none;
  min-width: 11rem;
}

.target_friend h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-left: 10px;
  color: #6c757d;
}

.user_sec {
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  padding: 5px 10px;
}

.target_friend .dropdown-item {
  padding: 0;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: unset;
}

.template_cols {
  /* background-image: url(../images/template_banner.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 5px;
}

.templates_sec .head_top h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  color: #181818;
  margin-left: 17px;
}

.templates_sec .head_top {
  margin-right: 0px;
  flex: 0.5;
}

.templates_sec .head_top.search_field {
  margin-right: 18px;
}

.templates_sec .head_top.search_field {
  width: 40%;
}

.template_message .form-control {
  background: #ffffff;
  border-radius: 7.05508px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  border: none;
  /* height: 140px; */
}

textarea {
  resize: none;
}

.template_message .form-control::placeholder {
  color: #181818;
}

.template_message a img {
  margin-right: 10px;
}

.template_message {
  margin-bottom: 20px;
  width: 98%;
  margin-right: 2%;
}

.template_cols .head_top.search_field {
  width: 100%;
}

.template_cols .form-control {
  background: #f9f2fa !important;
  border: 0.271042px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 6.77606px !important;
}

.template_cols .input-group-text {
  background: #f9f2fa !important;
  border: 0.271042px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 6.77606px !important;
}

.template_cols .form-control::placeholder {
  color: #181818;
}

.template_cols .head_top a {
  background: #f9f2fa !important;
  border: 0.271042px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 6.77606px !important;
}

.template_cols .head_top.search_field {
  margin-right: 10px;
}

.template_cols .head_top .dropdown-item {
  background: #fff !important;
  border: none !important;
}

.template_cols .head_top .dropdown-menu {
  background: #fff !important;
}

.template_cols .add_folder {
  background: #572f5b;
  border-radius: 6.77606px;
  border: none;
  text-decoration: none;
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
}

.template_cols .add_folder:hover {
  color: #fff;
  background-color: #380a3d;
  border-color: #572f5b;
}

.template_cols .add_folder img {
  margin-right: 10px;
}

.select_folder_col {
  width: 60%;
}

.select_folder {
  border-top: 0.406564px solid rgba(0, 0, 0, 0.11);
  border-bottom: 0.406564px solid rgba(0, 0, 0, 0.11);
  margin-top: 10px;
  padding: 7px 0px 10px;
}

.select_folder_col_1 {
  width: 40%;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}

.select_folder_col_1 img {
  margin-left: 10px;
}

.select_folder span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 9px;
  line-height: 21px;
  color: #181818;
}

.template_tags {
  border-radius: 6.65158px;
  width: 100%;
  margin-left: 7px;
}

.template_col {
  margin-top: 10px;
  margin-right: 10px;
}

.template_tags h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 13.3032px;
  line-height: 20px;
  padding: 10px;
  text-transform: capitalize;
  color: #000000;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: break-all;
  background-color: #F9F2FA;
  border-radius: 8px;
}

.delete_modal h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.delete_modal p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 80%;
  margin: 0 auto;
  color: #181818;
}

.delete_modal .cancel_btn {
  background: #d9d9d9;
  color: #181818;
}

.delete_modal .btn {
  border-radius: 8.5696px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding: 10px 15px;
  margin-right: 10px;
}

.delete_modal .delete_btn {
  background: #572f5b;
  color: #ffffff;
}

.delete_modal .action_btn {
  margin-top: 23px;
  margin-bottom: 20px;
}

a.add_tag.button_global {
  background: linear-gradient(109.47deg,
      #572f5b 13.06%,
      #af5e9d 67.2%,
      #572f5b 101.91%) !important;
  border: 1px solid rgba(87, 47, 91, 0.47);
  box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
  border-radius: 5px;
  font-style: normal;
  text-decoration: none;
  padding: 10px 13px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.redBordervalidationerror {
  border: 1px solid red;
}

.dropdown_tag {
  background: #ffffff;
  border: 1px solid #f5ebf5 !important;
  border-radius: 5px;
  display: block;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-decoration: none;
  color: #181818;
}

.dropdown_arrow_toggle::after {
  display: none !important;
}

.dropdown_arrow_toggle {
  background: none !important;
  border: none;
}

.textareaclass {
  border: 2px solid #673767;
  border-radius: 9px;
  width: 260px;
  margin: 0 auto;
  height: 115px;
}

.updatmodaltag {
  padding: 15px;
  /* height: 50px; */
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
}

.updatmodaltag input {
  height: 14px;
  width: 14px;
}

.dropdown_tag_new {
  background: #f9f2fa !important;
  border: 1px solid #f5ebf5 !important;
  border-radius: 5px;
  display: block;
  font-style: normal;
  padding: 10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-decoration: none;
  color: #181818;
}

.new_add_button {
  background: #572f5b;
  padding: 7px;
  border-radius: 9px;
  position: absolute;
  bottom: 4%;
  right: 2%;
  cursor: pointer;
}

.rdw-editor-toolbar {
  background: inherit;
  border: inherit;
}

.rdw-option-wrapper {
  border: inherit;
  background: inherit;
}

.left_border_column {
  border-left: 1px solid #d9d6da;
  min-height: 230px;
}

h3.template_button_active {
  background: #AF5E9D;
  border-radius: 8px;
  color: #ffffff;
}

.success_message {
  color: #66b718 !important;
}

.not_success_message {
  color: #fa5f1c !important;
}

.react-dropdown-select-dropdown {
  width: 180px !important
}

span.react-dropdown-select-item {
  font-size: 12px;
}

.react-dropdown-select-content {
  font-size: 12px;
}

input.react-dropdown-select-input {
  font-size: 10px;
}

.dtqzBx div:first-child {
  white-space: nowrap;
  word-break: break-all;
  white-space: break-spaces !important;
}

.custom-file-upload {
  border-radius: 50%;
  display: flex;
  position: relative;
  padding: 6px;
  cursor: pointer;
  margin-bottom: 25px;
}

#photo-upload {
  display: none;
}

/* .upload-image-preview {
  width: 200px;
  border-radius: 50%;
  height: 200px;
} */

.team_Profile_cols.active {
  background: #713777;

}

.h4_change.active {
  color: #ffffff;
}

.col-md-4.template_col_sec {
  background: #ffffff;
}

span.action_icons.last {
  margin-right: 0px !important;
  padding: 2px 14px;
  border-bottom-right-radius: 5px;
}

span.action_icons.first {
  border-bottom-left-radius: 5px;
}

span.action_icons.last {
  border-bottom-right-radius: 5px;
}

/**********table***********/
.right_side .WQNxq {

  background: #FFFFFF;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
  padding: 12px;
}

.right_side .sc-dkrFOg.iSAVrt.rdt_TableHeadRow {
  background: #F2F2F2;
  border-radius: 10px;
  border-bottom: none;
}

.cxcOiE:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent !important;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  border-bottom-color: #FFFFFF;
  outline-style: solid;
  outline-width: 1px;
  outline-color: none;
  border-bottom: none;
}

.rdt_TableBody .rdt_TableCell {
  border-bottom: 1px solid #d3d2d2;
}

.rdt_TableRow:hover {
  border: none;
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 40px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.target_friend_request {
  background-color: rgba(175, 94, 157, 0.14);
  margin: 8px;
  border: none;
  color: #181818;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  padding: 12px 45px;
}

.target_friend_requests .dropdown-menu.show {
  display: block;
  background: #FAFAFA;
  border-radius: 5px;
  border: none;
  width: 220px;
}

.target_friend_requests .dropdown-item {
  padding: 18px 0px 5px 0px;
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #6C757D;
}

.target_friend_requests .dropdown-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0px 10px;
}

.target_friend_requests a.dropdownItem.d-flex.dropdown-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.target_friend_requests .dropdown-menu.show {
  min-width: 220px;
  max-width: 300px;
  width: auto !important;
}

.target_friend_requests a.dropdownItem.d-flex.dropdown-item p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 165px;
}

.target_friend_requests .dropdown-item:last-child {
  border-bottom: none;
}

.target_friend_request:hover {
  background-color: #572F5B;
  color: #ffffff;
}


/* Change the background color of the dropdown button when the dropdown content is shown */


/****************login pages************/

.login_box {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 15px 25px;
  margin-top: 20px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login_box h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #572F5B;
}

.login_pages h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  text-align: center;
  color: #747B84;
}

.login_pages h4 a {
  color: #AF5E9D;
  text-decoration: none
}

.login_box p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(65, 79, 104, 0.68);
}

.login_box .form-control {
  background: #FFFFFF;
  border: 1px solid #F2EEEE;
  border-radius: 5px;
  font-style: normal;
  border-left: none;
  font-weight: 400;
  padding-left: 0;
  height: 40px;
  font-size: 12px;
  line-height: 15px;
}

.login_box .form-control::placeholder {
  color: rgba(65, 79, 104, 0.68);
}

.login_box .input-group-text {
  background: #FFFFFF;
  border: 1px solid #F2EEEE;
  border-radius: 5px;
}

.login_box .btn {
  background: #572F5B;
  border: 1px solid #462649;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #FFFFFF;
}

.login_pages .links a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0px;
  text-decoration: none;
  text-align: center;
  color: #AF5E9D;
  border-right: 1px solid #AF5E9D;
  padding-right: 10px;
  padding-left: 13px;
}

.login_pages .links {
  margin-top: 20px;
}

.support {
  border-right: none !important;
}

.swal2-icon.swal2-warning {
  border-color: #572F5B;
  color: white;
  background-color: #572F5B;
}



.swal2-styled.swal2-cancel {
  color: black;
}

.fa {
  color: #572f5b;
}

.tag_col_sec .react-colorful {
  position: absolute !important;
  z-index: 999;
}

.whiteiconcolor {
  color: white;
}

.tag_search_button {
  border: none;
  background-color: white;
  border: 1px solid #f5ebf5;
  border-left: none;
}

.a-btn-tag {
  background: #af5e9d;
  border-radius: 5px;
  font-style: normal;
  text-decoration: none;
  padding: 9px 13px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.a-btn-tag img {
  margin-right: 10px;
}

.react-dropdown-select-dropdown-handle svg {
  display: none;
}

.react-dropdown-select-dropdown-handle::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.25 11.25H8.75V10H6.25V11.25ZM1.875 3.75V5H13.125V3.75H1.875ZM3.75 8.125H11.25V6.875H3.75V8.125Z' fill='%23181818'/%3E%3C/svg%3E");
}

.css-1yc4zyy-DropdownHandleComponent {
  transform: none !important;
}

.react-dropdown-select-dropdown-handle {
  transform: rotate(0deg) !important;
  margin-bottom: -3px !important;
}

.react-dropdown-select-dropdown-handle.css-1aarvou-DropdownHandleComponent.e1vudypg0 {
  margin-bottom: 0px !important;
}

.row.tags_sec {
  display: flex !important;
  /* column-gap: 10px; */
  row-gap: 20px;
  margin: 5px 10px;
  overflow-x: hidden;
  padding-bottom: 16px;
  max-height: calc(100vh - 211px);
}

.col-2.tag_col_sec {
  width: 120px;
  height: 90px;
  padding: 0px;
  margin: 5px 10px;
}

.img-wrap.img-upload {
  width: 165px;
  border-radius: 50%;
  height: 165px;
  background: #E7E7FF;
  text-align: center;
  padding: 30px;
  position: relative;
  border-radius: 50%;
}

.img-wrap.img-upload span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255 255 255 / 49%);
  display: flex;
  justify-content: center;
  align-items: center;
}

input.form-control {
  color: #181818;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.right_side p {
  color: var(--light-secondary, #6C757D);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.sc-eDWCr.hbotQl {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.react-dropdown-select.dropdown_tag_new.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
  width: 180px;
  border-radius: 5px;
  border: 1px solid #dedede !important;
}

.editor-class.rdw-editor-main {
  padding: 0px 14px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #572f5b #f9f2fa;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #572f5b;
  border-radius: 10px;
  border: 3px solid #f9f2fa;
}

.scroll-area {
  max-height: 460px;
  overflow-y: auto;
}

.tag-contacts {
  background: #fff;
  /*  height: calc(100vh - 75px);
  overflow-y: scroll; */
}

.all-contacts-in-this-tag {
  height: calc(100vh - 73px - 110px);
  overflow-y: auto;
  padding: 0px 20px;
}

.new_add_button-2 {
  background: #fff;
  padding: 5px 10px;
  border: 1px solid #f5ebf5;
  border-radius: 5px;
  cursor: pointer;
}

.add_tag.button_global {
  background: linear-gradient(109.47deg, #572f5b 13.06%, #af5e9d 67.2%, #572f5b 101.91%) !important;
  border: 1px solid rgba(87, 47, 91, 0.47);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.add_tag.button_global svg {
  margin-right: 5px;
}

.rdw-inline-wrapper {
  display: flex;
  width: 33rem;
  justify-content: flex-end;
}

.rdw-editor-toolbar {
  background: none;
}

.new_add_button {
  background: none;
}

.rdw-dropdown-selectedtext {
  display: none;
}

.rdw-dropdown-wrapper {
  display: none;
}

.rdw-history-wrapper {
  position: absolute;
}



.row.tags_sec.m-0.p-0.demo {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.css-jznujr-ContentComponent {
  width: 146px;
}

.css-wmy1p7-ReactDropdownSelect {
  width: 180px;
}

.modal.contact-info-modal .modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.modal.contact-info-modal .modal-header {
  border-bottom: none;
}

.contact-info-submit-button {
  background: linear-gradient(109.47deg, #572f5b 13.06%, #af5e9d 67.2%, #572f5b 101.91%) !important;
  border-color: #572F5B;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.contact-info-submit-button:hover {
  background: linear-gradient(109.47deg, #572f5b 13.06%, #af5e9d 67.2%, #572f5b 101.91%) !important;
  border-color: #572F5B;
}

.modal.contact-info-modal .no-data {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
}

.modal.contact-info-modal label.form-check-label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #181818;
}

.modal.contact-info-modal .label-key-value-pair .label-key {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 2px;
  width: 330px;
}

.contact-info-modal .icon-container-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-info-modal .icon-container-col svg.disabled {
  fill: #DADADA;
  cursor: not-allowed;
}

.contact-info-modal .icon-container-col svg {
  height: 18px;
  width: 18px;
  color: #747B84;
  cursor: pointer;
}

.contacts_list .dropdown-item {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #181818;
}

.tag-search {
  border: 1px solid #f5ebf5;
  border-radius: 7px;
  width: 93%;
  background: #fcf6fb;
}

.tag-search input.form-control {
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
}

.tag-search input.form-control~span.input-group-text {
  background: transparent;
  border: none;
}

.error-message {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0px 5px;
  color: red;
  position: absolute;
  bottom: -19px;
  font-weight: 600;
}

.left_side .nav-link svg {
  background: #fff;
  fill: #572f5b;
  padding: 8px;
  height: 36px;
  margin-top: -8px;
  width: 36px;
  border-radius: 5px;
  margin-right: 10px;
}

.left_side .nav-link.active svg {
  background: #572f5b;
  fill: #fff;
  box-shadow: 2px 6px 6px 0px #ecd9e8;
}

.infobox-icons {
  height: 30px !important;
  width: 30px !important;
  padding: 4px;
  border-radius: 5px;
  margin-right: 10px;
}

.infobox-icons.home {
  fill: #fa582e;
  background: #fee6e0;
}

.infobox-icons.basket {
  fill: #0163de;
  background: #d9e8fa;
}

.infobox-icons.inventory {
  fill: #0fbb59;
  background: #dbf5e6;
}

.infobox-icons.volunteer {
  fill: #7828dc;
  background: #ebdffa;
}

.row.tags_sec.userdashboard .tag_cols {
  min-height: 90px !important;
}

.row.tags_sec.userdashboard .tag_cols h2 {
  font-size: 11px !important;
}

.team_Profile_cols.primay-account {
  background: linear-gradient(109.47deg, #572f5b 13.06%, #af5e9d 67.2%, #572f5b 101.91%) !important;
}

.team_Profile_cols svg {
  fill: #713777;
  width: 50px;
  padding: 13px 0px;
  height: 50px;
  cursor: pointer;
}

.team_Profile_cols h4 {
  color: #713777;
}

.team_Profile_cols.primay-account svg,
.team_Profile_cols.active svg {
  fill: #fff;
}

.team_Profile_cols.primay-account h4,
.team_Profile_cols.active h4 {
  color: #fff;
}

.row.bulk_message_cols.account_page .message_sec p {
  color: #181818;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.badge-style {
  position: absolute;
  top: -4px;
  right: 0px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #af5e9d !important;
}

.mr-2 {
  margin-right: 10px;
}

.navbar-nav-main {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
}

.dropbtn-top-style {
  padding: 5px;
  background: #E7E7FF;
  border-radius: 50%;
}

svg.notfi-icon-top {
  fill: #768396;
  width: 25px;
  height: 25px;
}

input.form-control[type="search"] {
  font-weight: 600;
  color: #572f5b;
}

input.form-control[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

.notification {
  position: absolute;
  width: 820px;
  right: 80px;
  top: 80px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 22px 13px -18px rgba(135, 116, 87, 0.10), 0px 10px 9px -4px rgba(135, 116, 87, 0.10), 0px 1px 3px 0px rgba(135, 116, 87, 0.10), 0px 1px 2px 0px rgba(135, 116, 87, 0.10), 0px -1px 15px 0px rgba(135, 116, 87, 0.10);
  z-index: 10;
  padding: 20px;
  height: 460px;
}

.notification>h2 {
  color: #572F5B;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.656px;
}

.notification button.mark-all-read {
  color: #6D6D6D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  background: none;
  border: none;
  padding: unset;
  float: right;
  position: relative;
  top: -20px;
}

.notification button.previous-notifications {
  color: #283C46;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.656px;
  background: none;
  border: none;
  padding: unset;
  position: absolute;
  bottom: 20px;
}

img.notif-uicon {
  width: 36px;
  height: 36px;
  border-radius: 20px;
}

span.notif-uicon {
  display: flex;
  padding: 15px 7.5px 15px 7.5px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: linear-gradient(0deg, #AF5E9D 0%, #AF5E9D 100%), #DDD;
  color: #FFF;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  width: 36px;
  height: 36px;
}

.notification ul {
  margin: 10px 0px;
}

.notification ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
  cursor: pointer;
}

span.notif-readme-dot-parent {
  color: #af5e9d;
}

span.notif-readme-dot-parent .notif-readme-dot {
  width: 10px;
}

.notif-desc {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3.672px;
  margin-left: 10px;
}

p.notif-desc span {
  color: #525252;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

p.notif-desc time {
  color: #717171;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

p.notif-desc b {
  color: #AF5E9D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

p.notif-desc b.nanchor {
  color: #af5e9db3;
}

p.notif-desc a {
  color: #AF5E9D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
}

.notification ul {
  margin: 0px 0px 0px 0px;
  height: 360px;
  overflow-y: hidden;
  width: 100%;
}

.notification ul:hover {
  overflow-y: auto;
}

.notification ul::-webkit-scrollbar {
  width: 10px;
}

.col-md-5.search-content-area {
  min-height: 600px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
}

.search-content-area h3{
  color: #414141;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-content-area p.searched-query-string {
  color: #414141;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.col-md-5.search-content-area .contact_list_item {
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  margin-top: 0px;
  padding: 5px 0px;
}

.col-md-5.search-content-area .contact_list_item #dropdown-basic-search{
  padding: 0;
}

.col-md-5.search-content-area .all-contacts-in-this-tag {
  padding: 0px 20px;
  margin-top: 10px;
  height: unset;
  overflow: unset;
}

.col-md-5.search-content-area .dropdown-menu.show {
  font-size: 12px;
  border-radius: 6.729px;
  background: #FAFAFA;
  box-shadow: 0px 0px 8.075px 0px rgba(24, 24, 24, 0.12);
  padding: 3px 0px;
}

.col-md-5.search-content-area .dropdown-item.active, .col-md-5.search-content-area  .dropdown-item:active{
  background-color: transparent;
  color: #af5e9d !important;
}

.col-md-5.search-content-area .profile-image{
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 15px;
}

.col-md-5.search-content-area .header-search-area > h4 {
  border-radius: 5px;
  border: 1px solid #F5EBF5;
  background: #AF5E9D;
  width: 153px;
  height: 36px;
  padding: 7px 10px;
  color: #FFF;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}

.col-md-5.search-content-area .header-search-area {
  margin: 15px 0px;
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  padding-bottom: 15px;
}

.col-md-5.search-content-area .searched-tags-area ul {
  margin-top: 10px;
}

.col-md-5.search-content-area .searched-tags-area ul > li {
  margin-bottom: 10px;
  width: 250px;
  border-radius: 5px;
  background: #EAF4E0;
  color: #181818;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-transform: capitalize;
  padding: 8px 10px;
  cursor: pointer;
}

.col-md-7.notif-content-area {
  min-height: 600px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
}

.col-md-5.cp-content-area {
  min-height: 400px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
}

.col-md-7.notif-content-area ul {
  margin: 10px 0px;
}

.col-md-7.notif-content-area li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 15px 0px;
  border-bottom: 0.3px solid rgba(74, 70, 73, 0.21);
  padding-bottom: 20px;
}

.notif-readme-close{
  cursor: pointer;
}

.col-md-7.notif-content-area p.empty-notif{
  color: #414141;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-md-7.notif-content-area b {
  color: #AF5E9D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.col-md-7.notif-content-area a {
  color: #AF5E9D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
}

.notification ul li:hover {
  background: #f5f5f5;
  padding: 20px;
}

.text-right{
  text-align: right;
}

img.linked_user_image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

img.upload-image-preview {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

img.dropbtn.dropbtn-top-style.user-image {
  object-fit: cover;
}